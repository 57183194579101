import _ from 'lodash';

import AuthService from 'service/AuthService';
import { getUserInformation, updateUserPreferences, createUserEvent, updateUserEvent, getUserManager, getUserById } from 'axios/UserService';

const authService = new AuthService();

export default function useRequests(state: any, dispatch: any, actions: any) {
  return () => ({
    isAuthenticated: () => authService.isAuthenticated(),
    signinRedirect: async () => authService.signinRedirect(),
    getUser: async () => authService.getUser(),
    isAdmin: async () => authService.isAdmin(),
    isManager: async () => !!state.user?.isManager,
    logout: async () => authService.logout(),
    setCurrentUser: async () => {
      if (_.isEmpty(state.user) && !state.groupTypesToShow.length && authService.isAuthenticated()) {
        try {
          const { data: user } = await getUserInformation();
          const {
            preferences: {
              filters: { groups: groupTypesToShow },
            },
          } = user;
          dispatch(actions.UPDATE_STATE({ user, groupTypesToShow }));
        } catch (error) {
          console.log(error);
          dispatch(actions.UPDATE_STATE({ lastUserEvent: { type: 'checkOut' } }));
        }
      }
    },
    // TODO verify if group preferences are still used
    updateGroupTypesToShow: async (groupTypesToShow: Array<string>) => {
      const payload = { filters: { groups: groupTypesToShow } };
      await updateUserPreferences(payload);
      dispatch(actions.UPDATE_STATE({ groupTypesToShow }));
    },
    changeAppContent: (contentToShow: string) => {
      dispatch(actions.UPDATE_STATE({ contentToShow }));
    },
    createUserEvent: async (event: any) => {
      const { data: lastUserEvent } = await createUserEvent(event);
      dispatch(actions.UPDATE_STATE({ lastUserEvent }));
      return lastUserEvent;
    },
    updateUserEvent: async (event: any, eventId: string, userId: string, startDate: string, endDate: string) => {
      const { data } = await updateUserEvent(event, eventId, userId, startDate, endDate);
      return data;
    },
    getUserManager: async (userId: string) => {
      const { data } = await getUserManager(userId);
      return data;
    },
    getUserById: async (userId: string) => {
      const { data } = await getUserById(userId);
      return data;
    },
  });
}
