import { UserEventsRequest } from '../types';

import { BaseService } from './BaseService';
import { RequestOptions } from './RequestOptions';

export class UserEventsService extends BaseService {
  constructor() {
    super('/api/v1/events');
  }

  async getMapEvents(requestParameters: UserEventsRequest): Promise<RequestOptions> {
    const { queryOptions, selectedGroup, startWeek, selectedUsers } = requestParameters;
    const eventsByGroupIdUrl = `/${selectedGroup?._id}/users/${startWeek.toISOString()}?`;
    const selectedUsersIds = selectedUsers?.map((user: any) => user._id);
    let requestPath = selectedGroup?._id ? eventsByGroupIdUrl : `/${startWeek.toISOString()}?`;
    const userIdFilter = selectedGroup?._id ? '_id' : 'userId';
    requestPath = (selectedUsers && selectedUsers.length > 0)
      ? `${requestPath}filters[]=${userIdFilter} $in ${selectedUsersIds}&` : requestPath;
    return this.options({ url: this.urlFor(queryOptions, this.path, requestPath) });
  }

  async getLastUserEvent(queryOptions = {}) : Promise<any> {
    return this.options({ url: this.urlFor({}, this.path, '') });
  }

  async getLastEventByUserId(userId: string): Promise<any> {
    const path = `${this.path}/${userId}/lastEvent`;
    return this.options({ url: this.urlFor({}, path, '') });
  }

  async createEvent(body: any): Promise<RequestOptions> {
    return this.options({ method: 'POST', url: this.urlFor({}, this.path, ''), data: body as any });
  }

  async updateEvent(
    body: any,
    eventId: string,
    userId: string,
    startDate: string,
    endDate: string,
  ): Promise<RequestOptions> {
    const requestPath = `/${eventId}/users/${userId}?startDate=${startDate}&endDate=${endDate}`;
    return this.options({ method: 'PATCH', url: this.urlFor({}, this.path, requestPath), data: body as any });
  }
}
