import actions from './actions';

function updateState(state: any, action: any) {
  return { ...state, ...action.payload };
}

function updateUserEventState(state: any, action: any) {
  const { user } = state;
  const { lastUserEvent } = action.payload;
  if (user._id === lastUserEvent.userId) {
    const newState = { ...state };
    const newlastUserEvent = { ...newState.lastUserEvent, ...lastUserEvent };
    return { ...newState, lastUserEvent: newlastUserEvent };
  }
  return { ...state };
}

export default function eventReducer(state: any, action: any) {
  switch (action.type) {
    case actions.UPDATE_STATE().type:
      return updateState(state, action);
    case actions.UPDATE_GLOBAL_EVENT_STATE().type:
      return updateUserEventState(state, action);
    default:
      return state;
  }
}
