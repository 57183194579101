import { Dispatch } from 'react';

export interface Event {
  _id: string,
  type: string,
  place: string,
  location: { type: string, coordinates: [number, number] },
  createdAt: string,
}

export interface Location {
  coordinates: [number, number],
}

export interface UsersEvent {
  _id?: string,
  updatedAt: string,
  location: Location,
  place: string,
  type: string,
  name: string,
  picture: string,
}

export interface QueryOptions {
  fields?: string[],
  filters?: Array<string>,
  orders?: Array<string>,
  limit?: number,
  skip?: number,
}

export interface WithAuthResponse {
  headers: {
    Authorization: string,
  },
}

export interface DataRequest {
  method?: string,
  url: string,
  data?: [],
  headers?: {
    Authorization: string,
  },
}

export interface MenuItems {
  iconData: {
    text: string,
    id: string,
  },
  coordsData: {
    navigate?: any,
    setCoords?: Dispatch<React.SetStateAction<string>>,
    coords?: string,
    to: string,
    exact?: boolean,
  },
}

export interface RequestOptions {
  queryOptions: QueryOptions,
}

export interface UserAttendanceRequest {
  queryOptions: QueryOptions,
  startDate: Date,
  endDate: Date,
  selectedGroup?: any,
  searchTerm?: string,
  selectedGroupUser: any,
  projectId?: string,
}

export interface UserEventsRequest {
  queryOptions: QueryOptions,
  startWeek: Date,
  selectedGroup?: any,
  selectedUsers?: any,
}

export interface GroupRequest {
  queryOptions: QueryOptions,
  startWeek?: Date,
  selectedGroup?: any,
  search?: string,
  type?: string,
}

export interface Group {
  available: number,
  notAvailable: number,
  users: Array<any>,
  name: string,
  members: Array<any>,
  ownerIds?: Array<string>,
  isFavorite?: boolean,
  type: string,
  _id: string,
}

export interface SidebarProps {
  selectedGroup?: Group,
  setSelectedGroup?: any,
  selectedMember?: any,
  setSelectedMember?: any,
  selectedGroupId?: string,
  setSelectedGroupId?: any,
}

export interface ErrorProps {
  title: string,
  message: string,
}

export interface QueryOptionsTimesheet extends QueryOptions {
  startWeek?: string,
  endWeek?: string,
  tz?: string,
}

export interface UsersEventWithAction {
  _id?: string,
  userId?: string,
  onOpen?: any,
  updatedAt: string,
  location: Location,
  place: string,
  type: string,
  markerType?: string,
  label?: number,
  createdAt?: string,
  timezoneOffset: string,
  userDateTime: string,
}

export interface User {
  _id: string,
  email: string,
  picture: string,
  name: string,
  preferredUsername: string,
}

export interface SkypeGroup {
  name: string,
  _id: string,
  newGroup?: boolean,
  users: Array<string>,
  type: string,
  weeklyTimesheet?: Time,
  weeklyCheckInOut?: Time,
}

export interface Time {
  day: string,
  time: string,
  timezoneOffset: string,
}

export interface WebhookGroup {
  name: string,
  _id: string,
  newGroup?: boolean,
  users: Array<string>,
  type: string,
  weeklyTimesheet?: Time,
  weeklyCheckInOut?: Time,
}

export enum SourceApp {
  WEB = 'web',
  MOBILE = 'mobile',
  TEAMS = 'msteams',
  SYSTEM = 'system',
}
